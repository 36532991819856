export class EnvironmentConfig {
  production = false;
  apiUrl = 'https://api.dev.examfactor.co';

  get authConfig() {
    return {
      issuer: 'https://identity.dev.examfactor.co',
      realm: 'exam-factor',
      clientId: 'lms-client', // The "Auth Code + PKCE" client
      responseType: 'code',
      redirectUri: `${window.location.href}?_=123`,
      silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
      scope: 'openid profile email roles phone', // Ask offline_access to support refresh token refreshes
      useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
      sessionChecksEnabled: true,
      showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
      clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
      nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
      enableBearerInterceptor: true,
      excludedUrls: [],
    };
  }

  shouldAddToken(request: any) {
    return true;
  }

  get allowedUrls() {
    return [this.apiUrl];
  }

  get teacherApiUrl() {
    return `${this.apiUrl}/teacher-service`;
  }

  get organizationApiUrl() {
    return `${this.apiUrl}/organization-service`;
  }

  get authApiUrl() {
    return `${this.apiUrl}/auth-service`;
  }
  get masterDataApiUrl() {
    return `${this.apiUrl}/master-data-service`;
  }
  get studentApiUrl() {
    return `${this.apiUrl}/b2b-student-service`;
  }
  get faqApiUrl() {
    return `${this.apiUrl}/faq-service`;
  }
  get masterApiUrl() {
    return `${this.apiUrl}/master-data-service`;
  }

  get assessmentApiUrl() {
    return `${this.apiUrl}/assessment-service`;
  }

  get questionnaireApiUrl() {
    return `${this.apiUrl}/questionnaire-service`;
  }
}

export const environment = new EnvironmentConfig();
