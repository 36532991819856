import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private http: HttpClient) {}

  requestLogin(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.authApiUrl}/v1/auth/login`,
      payload
    );
  }

  requestLogout(payload: { refreshToken: string }): Observable<void> {
    return this.http.post<any>(
      `${environment.authApiUrl}/v1/auth/logout`,
      payload
    );
  }

  sendVerificationCode(
    phoneNumber: string
  ): Observable<{ expires_in: number }> {
    return this.http.get<any>(
      `${environment.authApiUrl}/v1/auth/sms/verification-code?phoneNumber=${phoneNumber}`
    );
  }

  refreshToken(payload: { refreshToken: string }): Observable<any> {
    return this.http.put<any>(
      `${environment.authApiUrl}/v1/auth/refresh_token`,
      payload
    );
  }
  resetPassword(payload: any) {
    return this.http.put<any>(
      `${environment.authApiUrl}/v1/auth/reset-password`,
      payload
    );
  }
  getPassword(payload: any) {
    return this.http.put<any>(
      `${environment.authApiUrl}/v1/auth/forgot-password`,
      payload
    );
  }
}
