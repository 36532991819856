import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, mergeMap, of, Subject } from 'rxjs';
import { AuthApiService } from './auth-api.service';
import { Token } from './modals/decodeToken.type';

@Injectable({ providedIn: 'root' })
export class AuthService {
  tokenEventNotifier = new Subject<{ type: string }>();
  authorizationHeaderName = 'Authorization';
  bearerPrefix = 'Bearer';
  inUpdating = false;
  watcher: any;

  constructor(private authApiService: AuthApiService) {}

  async isLoggedIn() {
    try {
      let token = await this.decodedAccessToken();
      let tokenExp = token.exp * 1000;
      return new Date().getTime() < tokenExp;
    } catch (error) {
      return false;
    }
  }

  logout() {
    return from(this.refreshToken).pipe(
      mergeMap((refreshToken) =>
        this.authApiService.requestLogout({ refreshToken })
      )
    );
  }

  async refresh() {
    await this.updateToken();
  }

  async accessToken() {
    let rt = localStorage.getItem('access_token');
    if (rt) {
      return Promise.resolve(rt);
    } else {
      return Promise.reject('Not found');
    }
  }

  get refreshToken() {
    let rt = localStorage.getItem('refresh_token');
    if (rt) {
      return Promise.resolve(rt);
    } else {
      return Promise.reject('Not found');
    }
  }

  async permissions(): Promise<string[]> {
    let allPermissions: any = this.decodeToken(
      await this.accessToken()
    ).resource_access;
    let permissions: string[] = [];
    for (let permission in allPermissions) {
      permissions = [...permissions, ...allPermissions[permission]['roles']];
    }
    return permissions;
  }

  async roles() {
    return this.decodeToken(await this.accessToken()).realm_access.roles;
  }

  async userType(): Promise<string> {
    return this.decodeToken(await this.accessToken()).userType;
  }

  async managePartner(): Promise<string[]> {
    return this.decodeToken(await this.accessToken()).managePartners;
  }

  async decodedAccessToken() {
    return this.decodeToken(await this.accessToken());
  }

  async userName() {
    return this.decodeToken(await this.accessToken()).preferred_username;
  }

  async email() {
    return this.decodeToken(await this.accessToken()).email;
  }

  registerAutoRefresh() {
    if (!this.watcher) {
      this.updateToken();
    }
  }

  addTokenToHeader(req: HttpRequest<unknown>) {
    let token = localStorage.getItem('access_token') as string;
    if (token) {
      req.headers.set(
        this.authorizationHeaderName,
        this.bearerPrefix + ' ' + token
      );
    }
    return of(req.headers);
  }

  registerAndWatch(iCustomLoginSuccessResponse: any) {
    for (let key in iCustomLoginSuccessResponse) {
      localStorage.setItem(key, iCustomLoginSuccessResponse[key]);
    }
    let expIn = iCustomLoginSuccessResponse['expires_in'];
    this.watcher = setTimeout(() => {
      //this.tokenEventNotifier.next({ type: 'EXPIRE' });
      this.updateToken();
    }, expIn * 1000);
  }

  updateToken() {
    try {
      from(this.refreshToken)
        .pipe(
          mergeMap((rf) =>
            this.authApiService.refreshToken({ refreshToken: rf as string })
          )
        )
        .subscribe((newTokwnRes) => {
          console.log('refresh token api hit');
          this.registerAndWatch(newTokwnRes);
        });
    } catch (error) {
      console.log('error', error);
    }
  }

  async getExpInSec() {
    let at = await this.decodedAccessToken();
    let leftMili = (at.exp - new Date().getTime()) / 1000;
    return leftMili > 0 ? leftMili : 0;
  }

  decodeToken(token: string): Token {
    const jwtData = token.split('.')[1];
    const decodedJwtJsonData = window.atob(jwtData);
    const decodedJwtData = JSON.parse(decodedJwtJsonData);
    return decodedJwtData;
  }
}
